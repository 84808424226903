import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Svg from './svg/logo1.svg'

// import heroStyles from '../components/hero.module.css'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  h1{
    font-family: 'GT Walsheim Pro Bold';
  }

  .heroImage {
    position: relative;
    height: 600px;
    width: 600px;
    max-height: 450px;
  }

  @media (max-width: 600px) {
    .heroImage {
      width: 100%;
    }
  }
`

class ProjectOne extends React.Component {
  render() {
    return (
      <Layout location="/project-1">
        <Div
          style={{
            // background: '#f2f1e2',
            // borderRadius: 5,
            // boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
          }}
        >
          <Helmet title="О проекте: Изучай, Действуй, Сохраняй." />
          <div className="wrapper">
            <h1 className="section-headline">
              О проекте: Изучай, Действуй, Сохраняй.
            </h1>
            <p>
              Одной из стратегических задач, стоящих перед нашим обществом,
              является сохранение и актуализация природного и культурного
              наследия как национального достояния народов России. Приобщение
              молодого поколения к деятельности по изучению, популяризации и
              сохранению памятников истории и культуры формирует у него бережное
              отношение к культурному наследию и способствует профессиональному
              просвещению.
            </p>
            <img src={Svg} alt="изучай действуй сохраняй" />
            <p>
              Основная идея проекта заключается в создании условий для
              вовлечения старшеклассников и студенческой молодёжи в проектную и
              исследовательскую деятельность в сфере сохранения культурного
              наследия.
            </p>
            <p>
              Для этого на базе штаба Крымского республиканского отделения ВОО
              «ВООПИИК» при поддержке Фонда президентских грантов создаётся
              ресурсный центр с целью проведения информационно-просветительских
              мероприятий для обучающихся общеобразовательных учреждений и
              студентов вузов Республики Крым.
            </p>
            <p>
              Запланирована реализация обучающего курса по социокультурному
              проектированию, профессиональному просвещению в сфере сохранения
              культурного наследия. Дважды в месяц занятия будут проводиться на
              площадках общеобразовательных организаций Республики Крым и
              Крымского федерального университета имени В.И. Вернадского (далее
              – КФУ), включая выезды в не менее чем в 20 муниципальных
              образования региона. Логистика выездных обучающих мероприятий
              проекта позволит охватить школьников, проявляющих интерес к теме
              сохранения культурного наследия, во всех муниципалитетах
              Республики Крым.
            </p>
            <p>
              Членами Крымского республиканского отделения ВООПИИК совместно с
              Союзом реставраторов Крыма создаются площадки для проведения
              профессиональных проб на базе организации – партнера – КФУ с целью
              содействия школьникам и студентам в профессиональном
              самоопределении и освоении ими таких направлений подготовки, как
              история, реставрация, дизайн архитектурной среды, музеология и
              охрана культурного наследия.
            </p>
            <p>
              Студентам и школьникам, принявшим участие в обучающем курсе и
              профессиональных пробах, будет предложено на практике применить
              полученные знания, научиться командной работе в составе проектных
              групп и предложить на рассмотрение конкурсной комиссии свои
              инициативы в рамках проведения конкурса молодёжных социальных
              проектов в сфере сохранения культурного наследия. Жюри конкурса
              будет сформировано из экспертов КФУ, членов Крымского
              республиканского отделения ВОО "ВООПИИК", представителей
              профильных органов власти и организаций, педагогов.
            </p>
            <p>
              С целью презентации успешных кейсов, обмена опытом работы по
              обучению навыкам социального проектирования и внедрения методик
              активизации профессионального самоопределения весной 2021 года
              состоится практический семинар для педагогов, градозащитников и
              общественности Республики Крым и других субъектов Российской
              Федерации. В мероприятии примут участие не менее 50 человек из не
              менее 25 субъектов Российской Федерации.
            </p>
          </div>
        </Div>
      </Layout>
    )
  }
}

export default ProjectOne
